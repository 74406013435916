import { ITherapeut } from "./interface-therapeut";
import { PraxisLocation } from "./praxislocation";

export class Therapeut implements ITherapeut{

    ID: string;
    academicTitle: string; 
    firstName: string;
    secondName: string;
    Office1Address: string; 
    Office1PostalCode: string; 
    Office1Location: string; 
    Office2Address: string; 
    Office2PostalCode: string; 
    Office2Location: string; 
    Office3Address: string; 
    Office3PostalCode: string; 
    Office3Location: string; 
    MobileNumber: string; 
    LandLineNumber: string; 
    EMail: string; 
    Website: string; 
    PicturePath = "pic_vapp.jpg";
    TargetChildren: string; 
    TargetYouth: string; 
    TargetAdults: string; 
    AdditionalInfo: string; 
    allInfosUnfolded: boolean = false; 
    locations: PraxisLocation[] = [];

    qrCodeMobileNumber = "";
    qrCodeLandlineNumber = "";

    constructor (item: ITherapeut)
    {
        this.ID = item.ID;
        if (item.academicTitle) { this.academicTitle = item.academicTitle;}
        this.firstName = item.firstName;
        this.secondName = item.secondName;

        this.Office1Address = item.Office1Address;
        this.Office1PostalCode = item.Office1PostalCode;
        this.Office1Location = item.Office1Location;

        if (item.Office2Address) { this.Office2Address = item.Office2Address;}
        if (item.Office2PostalCode) { this.Office2PostalCode = item.Office2PostalCode;}
        if (item.Office2Location) { this.Office2Location = item.Office2Location;}

        if (item.Office3Address) { this.Office3Address = item.Office3Address;}
        if (item.Office3PostalCode) { this.Office3PostalCode = item.Office3PostalCode;}
        if (item.Office3Location) { this.Office3Location = item.Office3Location;}

        if (item.MobileNumber) { this.MobileNumber = item.MobileNumber;}
        if (item.LandLineNumber) { this.LandLineNumber = item.LandLineNumber;}
        if (item.EMail) { this.EMail = item.EMail;}
        if (item.Website) { this.Website = item.Website;}

        if (item.PicturePath) { this.PicturePath = item.PicturePath;}

        if (item.TargetChildren) { this.TargetChildren = item.TargetChildren;}
        if (item.TargetYouth) { this.TargetYouth = item.TargetYouth;}
        if (item.TargetAdults) { this.TargetAdults = item.TargetAdults;}

        if (item.AdditionalInfo) { this.AdditionalInfo = item.AdditionalInfo;}

        this.createLocations(); 
        this.createQRCodes();
        if (item.Website) { 
            item.Website = this.createWebsiteLink(item.Website); 
            console.log(item.Website);
        }
        
    }

    createLocations(): void
    {
        this.locations = [];
        var firstLocation = new PraxisLocation(
            this.Office1Address,
            this.Office1Location,
            this.Office1PostalCode,
            0,
            "Praxis"
        );
        this.locations[0] = firstLocation; 
        if (this.Office2Address)
        {
            var secondLocation = new PraxisLocation(
                this.Office2Address,
                this.Office2Location,
                this.Office2PostalCode,
                1,
                "Zweitpraxis"
            );
            this.locations[1] = secondLocation; 
        }
        if (this.Office3Address)
        {
            var thirdLocation = new PraxisLocation(
                this.Office3Address,
                this.Office3Location,
                this.Office3PostalCode,
                2,
                "Drittpraxis"
            );
            this.locations[2] = thirdLocation;
        }
    }

    createWebsiteLink(strAsLink: string): string
    {
        if (strAsLink.indexOf("https")!= 0)
        {
            // strAsLink = "https://" + strAsLink;
        }
        return strAsLink; 
    }

    createQRCodes (): void{
        this.qrCodeMobileNumber = "";
        this.qrCodeLandlineNumber = "";
        var austria_country_code = "+43 ";
        if ((this.MobileNumber != null) && (this.MobileNumber.length > 0))
        {
            this.qrCodeMobileNumber = this.MobileNumber.replace(/^0+/, '');
           
            if ( this.qrCodeMobileNumber.indexOf(austria_country_code) != 0)
            {
                this.qrCodeMobileNumber = austria_country_code + this.qrCodeMobileNumber;
            } 

            this.MobileNumber = this.qrCodeMobileNumber; 
           

        }
        if ((this.LandLineNumber != null) && (this.LandLineNumber.length > 0))
        {
            this.qrCodeLandlineNumber = this.LandLineNumber.replace(/^0+/, '');

            if ( this.qrCodeLandlineNumber.indexOf(austria_country_code) != 0)
            {
                this.qrCodeLandlineNumber = austria_country_code + this.qrCodeLandlineNumber;
            } 
            this.LandLineNumber = this.qrCodeLandlineNumber; 
        }
    
    }

}