<!-- cookie consent -->
<div class="row styledText" *ngIf="cookieConsentService.cookieSettings !=null && cookieConsentService.cookieSettings.cookieConsentIsVisible== true">
    <div class="col s12 m2 l2"></div>
    <div class="col s12 m10 l6">
        <div class="vappbgcolorlight black-text">
            <div class="right-align"><a class="btn vappbgcolor right-align" (click)="setCookieConsentVisisble()"><i class="material-icons">close</i></a></div>
            <div class="center-align styledTextBlue">Cookie Zustimmung</div>
            <br>
            <div class="center-align">
                Diese Internetseite verwendet Cookies durch Drittanbieter.
                <br>Durch die weitere Nutzung der Website stimmen Sie der Verwendung dieser Cookies zu.<br>Weitere Informationen hierzu finden Sie in unserer<br>
                <a class="styledTextBlue" routerLink="/dataprotection" routerLinkActive="active">Datenschutzerklärung.</a><br>Wir empfehlen unsere Cookies zu zulassen.
            </div>
            <hr>
            <div class="switch center-align">
                Google Maps: Um notwendige Geo-Location Links auf Google Maps zu präsentieren<br>
                <label class="styledTextBlue">
                Google Maps Off
                <input type="checkbox" (click)="mapsClicked()" [(checked)]="cookieConsentService.cookieSettings.googleMapsEnabled">
                <span class="lever"></span>
                Google Maps On
            </label>
            </div>
            <hr>
        </div>
    </div>
</div>

<div class="row styledText">
    <div class="col s12 m2 l2"></div>
    <div class="col s12 m10 l6">
        <div>
            <div>
                <h1>Datenschutzerklärung</h1>
                <div>Nach Vorgaben der Datenschutz-Grundverordnung der EU aus dem Jahr 2016 informieren wir Sie über alle Datenverarbeitungsvorgänge unserer Webseite. Falls dazu Fragen auftreten, bitte kontaktieren Sie uns über das <a class="styledTextBlue"
                        routerLink="//service/" routerLinkActive="active">Kontaktformular</a> oder andere Wege (siehe <a class="styledTextBlue" routerLink="//impressum/" routerLinkActive="active">Impressum</a>).<br> Wir halten uns an die gesetzlichen Bestimmungen
                    der EU‐Datenschutz‐Grundverordnung sowie an die österreichischen Datenschutzgesetze in der geltenden Fassung. Im Folgenden möchten wir Sie umfassend und transparent über die Verarbeitung Ihrer personenbezogenen Daten durch uns als
                    verantwortlichen Verein informieren.
                </div>
            </div>
            <div>
                <h2>Die Datenschutzerklärung behandelt folgende Inhalte:</h2>
                <ul>
                    <li>Besuch unserer Webseite</li>
                    <li>Cookies</li>
                    <li>Datenverarbeitung durch Nutzereingaben</li>
                    <li>Datenerhebung durch Server</li>
                    <li>Webanalyse, Tracking und Marketingdienste</li>
                    <li>Social Media Implementationen</li>
                    <li>Drittinhalte</li>
                </ul>
            </div>
            <div>
            </div>
        </div>
        <div>
            <div>
                <h2>Besuch der Webseite www.vapp.at</h2>
                <div>
                    Unsere Webseite ist ausschliesslich über HTTPS (sicheres Hypertext-Übertragungsprotokoll) erreichbar. Somit findet die Datenübertragung zwischen ihrem Browser und unserem Server verschlüsselt statt.
                </div>
            </div>
            <div>
                <h2>Cookies</h2>
                <div>
                    Cookies sind kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Unsere Website verwendet keine Cookies. Jedoch verwenden wir Dienste von Drittanbietern, die Cookies anlegen. Mehr Informationen entnehmen Sie bitte direkt
                    in den Cookie Einstellungen. Hier können Sie auch die Berechtigungen für das Setzen von Cookies entziehen oder zulassen. Ihre Entscheidungen haben Einfluss auf die Darstellung und Arbeitsweise unserer Werbseite.<br>
                    <a class="btn vappbgcolor right-align" (click)="setCookieConsentVisisble()"><i class="material-icons">settings</i>Cookie Einstellungen</a>
                    <br><br> Für Cookies, die von Google gesetzt werden, gelten folgende Datenschutzrichtlinien
                    <h3>Google Maps
                    </h3>
                    ist ein freier Kartendienst von Google, der uns ermöglicht, Ihnen Orte (Praxen von TherapeutInnen) direkt auf interaktiven Karten darzustellen.
                    <br>Für Google Maps gelten ebenso die Datenschutzbestimmungen von Google: <a class="styledTextBlue" href="https://policies.google.com/privacy?hl=de#intro" target="_blank">Datenschutzerklärung für Google Dienste</a>

                </div>
            </div>
            <div>
                <h2>Datenverarbeitung durch Nutzereingaben</h2>
                <div>
                    Den Schutz Ihrer personenbezogenen Daten nehmen wir sehr ernst. Anfragen und Daten, die wir von Ihnen über die Kontaktformular-Eingabe bekommen, werden von uns zwecks Erfassung und Bearbeitung ohne Zwischenspeicherung am Server übermittelt und nach entsprechender
                    Bearbeitung / Erledigung nach Verstreichung einer angemessenen Frist wieder unumgänglich gelöscht. Es besteht keinerlei rechtlicher Anspruch auf Be- und Verarbeitung Ihrer Daten in irgendeiner Art und Weise. Die Möglichkeit, über unsere
                    Servicestelle mit uns in Kontakt zu treten versteht sich als unentgeltlicher Service.
                </div>
            </div>
            <div>
                <h2>Datenerhebung durch Server</h2>
                <div>
                    Auf dem Server unserer Providers sind keine uns bekannten Datenverarbeitungsvorgänge eingehängt.
                </div>
                <h2>Webanalyse, Tracking und Marketingdienste</h2>
                <div>
                    Auf unserer Webseite werden keinerlei Webanalyse, Tracking oder Marketing Funktionalitäten verwendet. Das Surfverhalten unserer Webseitenbesucher wird von uns nicht protokolliert.
                </div>

                <h2>Social Media Implementationen</h2>
                <div>
                    Auf unserer Webseite werden keine Social Media Implementationen verwendet.
                </div>

                <h2>Drittinhalte:</h2>
                <div>
                    Für Drittinhalte auf unsere Webseite gelten die jeweilgen Bestimmungen der Anbieter und Betreiber. Das betrifft auch Besuche von externen Webseiten, die auf unserer Seite verlinkt sind.
                </div>
            </div>
            <hr>
            <div class="right-align">
                <a class="btn vappbgcolor" (click)="scrollToTop()"><i class="material-icons left">arrow_upward</i></a>
            </div>
        </div>
    </div>
</div>