import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.css']
})
export class ImpressumComponent implements OnInit {

  constructor(private scroll: ViewportScroller) { }
  ngOnInit(): void {}

  scrollToTop() {
    this.scroll.scrollToAnchor("mainHeader");
  }

}
