<div class="row styledText">
    <div class="col s12 m2 l2"></div>
    <div class="col s12 m4 l4">
        <h1>VAPP Servicestelle</h1>
        <strong>Servicetelefon:</strong>
        <br> +43 (0) 676 73 73 262
        <br>Telefondienst: Dienstag und Donnerstag von 11.00-14.00 Uhr
        <br>
        <a class="styledText" href="mailto:service@vapp.at?subject=Webanfrage%20an%20VAPP&body=Ihre%20Nachricht..."><i class="material-icons left" style="padding-top: 4px;margin-right: 5px;">email</i> service@vapp.at</a>
        <br><br>
        <strong>Adresse</strong>
        <br>VAPP Steiermark
        <br>Martinhofstrasse 76
        <br>8054 Graz
        <br>Österreich
    </div>

    <!--FORMULAR DISABLED -->
    <div *ngIf="formularDisabled" class="col s12 m5 l5">
        <h1>Kontaktformular</h1>
        <div>
           Das Formular ist momentan nicht verfügbar. Wir arbeiten an einer neuen Version.
        </div>
    </div>

    <!--FORMULAR-->
    <div *ngIf="!formularDisabled" class="col s12 m5 l5">
        <h1>Kontaktformular - Bitte geben Sie hier ihre Anfrage ein.</h1>

        <form class="form-container" #contactForm="ngForm" (ngSubmit)="submitForm(contactForm)">
            <div>
                <label for="fullname">Full Name</label>
                <input type="text" id="fullname" name="fullname" class="form-control styledText" ngModel required minlength="3" (input)="checkFormValidity(contactForm)">
            </div>
            <div>
                <label for="email">Email</label>
                <input type="email" id="email" name="email" class="form-control styledText" ngModel required email (input)="checkFormValidity(contactForm)">
            </div>
            <div>
                <label for="topic">Topic</label>
                <input type="text" id="topic" name="topic" class="form-control styledText" ngModel required minlength="3" (input)="checkFormValidity(contactForm)">
            </div>
            <div>
                <label for="message">Message</label>
                <textarea id="message" name="message" class="form-control materialize-textarea styledText" ngModel required minlength="3" (input)="checkFormValidity(contactForm)"></textarea>
            </div>
            <!-- HONEYPOT FIELD -->
            <div style="display: none;">
                <!-- Hidden honeypot field -->
                <label for="honeypot">Do not fill this field</label>
                <input type="text" id="honeypot" name="honeypot" ngModel>
            </div>
            <div class="userfeedbackcontainer" style="margin-top: 1rem;margin-bottom:1rem;">
                 <!-- <div>Alle Felder befüllt: {{isFormFilled}} valides Formular {{isFormValid}}</div>-->
                <div *ngFor="let uMessage of userFBMessages">
                    {{uMessage}}
                </div>
            </div>
            <!-- STANDARD BUTTONS -->
            <div class="form-btn-container" *ngIf="formControlButtonsAreVisible">
                <button class="btn vappbgcolor" type="button" (click)="resetForm(contactForm)">Formular leeren</button>
                <button class="btn vappbgcolor {{btnSubmitClassName}}" type="submit" [disabled]="!isFormValid">Abschicken</button>
            </div>
            <!-- PENDING -->
            <div class="" *ngIf="!formControlButtonsAreVisible && !appFeedbackAvailable">
                <div class="progress">
                    <div class="indeterminate"></div>
                </div>
            </div>
            <!-- SERVER FEEDBACK -->
            <div class="form-feedback-container userfeedbackcontainer" *ngIf="!formControlButtonsAreVisible && appFeedbackAvailable" style="margin-top: 1rem;margin-bottom:1rem;">
                <div>{{server_fb_message}}</div>
                <button class="btn vappbgcolor" type="button" (click)="resetForm(contactForm)" style="max-width:4rem;margin-top: 1rem;">OK</button>
            </div>
        </form>        
    </div>
</div>
