<div class="row styledText">
    <div class="col s12 m2 l2"></div>
    <div class="col s12 m10 l6">
        <div>
            <div>
                <h1>Impressum</h1>
                <div>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</div>
            </div>
            <div>
                <h2>VAPP Verein für Ambulante Psychologische Psychotherapie</h2>
                <div><strong>ZVR:</strong> 554059471</div>
                <div><strong>Adresse: </strong> Martinhofstrasse 76, 8054 Graz, Österreich
                </div>
            </div>
            <div>
                <h2>Organschaftliche Vertreter</h2>
                <strong>Obmann/Obfrau:</strong> Dr.in Luise Hollerer
                <div><strong>Obmann/Obfrau Stellvertreter:</strong> Mag.a Dr.in Sabine Wirnsberger</div>
                <strong>Kassier:</strong> Dr. Hubert Schaupp
            </div>
            <div>
                <div><strong> Vereinssitz:</strong> 8054 Graz</div><br>
                <div><strong> Tel.:</strong> +43 (0) 676 73 73 262</div>
                <div><strong>E-Mail: </strong>
                    <a class="styledText" href="mailto:service@vapp.at">service@vapp.at</a></div>
            </div>
        </div>
        <hr>
        <div>
            <h2>Haftung für Inhalte dieser Website</h2>
            <div>Die Inhalte der Webseite werden laufend aktualisiert. Wir sind stets bemüht, die Informationen unserer Webseite auf dem neuesten Stand zu halten. Jedoch kann keine Haftung für die Richtigkeit aller Inhalte auf dieser Website übernommen werden.
                Das gilt ganz besonders für alle Inhalte, die seitens Dritter bereitgestellt wurden.
            </div>
        </div>
        <div>
            <h2>Haftung für Links auf dieser Webseite</h2>
            <div>Unsere Webseite enthält Links zu anderen Webseiten, für deren Inhalt wir nicht verantwortlich sind.</div>
            <div>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.</div>
        </div>
        <div>
            <h2>Urheberrechtshinweis</h2>
            <div>Alle Inhalte dieser Webseite (betrifft auch Fotos und Texte) sind urheberrechtlich geschützt. Die Weiterverbreitung ist grundsätzlich nicht gestattet. Wir werden die unerlaubte Nutzung von Inhalten unserer Seite rechtlich verfolgen.</div>
            <div>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie, uns zu kontaktieren.</div>
        </div>
        <hr>
        <div class="right-align">
            <a class="btn vappbgcolor" (click)="scrollToTop() "><i class="material-icons left ">arrow_upward</i></a>
        </div>

    </div>
</div>