import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TherapeutDetailsComponent } from './therapeut-details/therapeut-details.component';
import { TherapeutsComponent } from './therapeuts/therapeuts.component';
import { ServiceComponent } from './service/service.component';
import { StepsComponent } from './steps/steps.component';
import { OfferComponent } from './offer/offer.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DataprotectionComponent } from './dataprotection/dataprotection.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CookieConsentService } from './app-servicies/cookie-consent-service';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

const routes: Routes = [
  { path: '#', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'app-home', component: HomeComponent },
  { path: 'offer', component: OfferComponent },
  { path: 'steps', component: StepsComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'therapeuts', component: TherapeutsComponent },
  { path: 'therapeutdetails/:id', component: TherapeutDetailsComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'dataprotection', component: DataprotectionComponent }
];



@NgModule({
  declarations: [
    AppComponent,
    TherapeutDetailsComponent,
    TherapeutsComponent,
    ServiceComponent,
    StepsComponent,
    OfferComponent,
    ImpressumComponent,
    DataprotectionComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    NgxQRCodeModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  providers: [
    CookieConsentService
  ],
  bootstrap: [AppComponent]
})



export class AppModule { }
