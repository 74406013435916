export class PraxisLocation {

    id = -1; 
    headline: string = "";
    address: string; 
    postalCode: string; 
    location: string; 
    googleMapLink = "https://www.google.com/maps?q=" 

    constructor (adress: string,location: string,plz: string, id: number,headline: string) {
        this.address = adress; 
        this.location = location; 
        this.postalCode = plz; 
        this.id = id;
        this.headline = headline; 
        this.googleMapLink = "https://www.google.com/maps?q=" + this.postalCode+"," + this.postalCode + "," + this.address;  
    }
}