import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CookieConsentService } from '../app-servicies/cookie-consent-service';

@Component({
  selector: 'dataprotection',
  templateUrl: './dataprotection.component.html',
  styleUrls: ['./dataprotection.component.css']
})
export class DataprotectionComponent implements OnInit {

  constructor(private scroll: ViewportScroller,public cookieConsentService: CookieConsentService) { }

  ngOnInit(): void {
  }

  scrollToTop() {
    this.scroll.scrollToAnchor("mainHeader");
  }

  setCookieConsentVisisble(): void
  {
    this.cookieConsentService.setCookieConsentVisisble(); 
    this.scrollToTop()
  }
  mapsClicked(): void
  {
    this.cookieConsentService.setGoogleMaps(); 
  }

}
