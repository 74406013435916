import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import * as M from 'materialize-css';

// initializing the .sidenav element

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  title = 'VAPP Website';
  constructor(private el:ElementRef) { }
  ngOnInit() { }
  ngAfterViewInit(): void {
    let options = {
      inDuration: 350,
      outDuration: 350,
      edge: 'left'

    }
    setTimeout( function() {
      var elem = document.querySelector('.sidenav');
      var instance = M.Sidenav.init(elem, options);
    }, 0)
  }
  ngOnDestroy() { }
}
