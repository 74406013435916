<div class="row" *ngIf="therapeuts.length <= 0">
    <div class="col s12 m12 l12 center-align">
        <br><br>
        <div class="preloader-wrapper small active">
            <div class="spinner-layer spinner-blue-only">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="gap-patch">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="therapeuts.length > 0">
    <div class="row hide-on-med-and-down">
        <div class="col s12 m2 l3">
        </div>
        <div class="col s12 m10 l9">
            <h1>VAPP Therapeut*innen - sortiert {{currentSortMethodDisplay}}</h1>
            <a class="btn vappbgcolor {{btSortBySecondName}}" (click)="sortTherapeutsBy('secondName', false)"><i class="material-icons left">sort_by_alpha</i>nach Nachnamen</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor {{btSortByOffice1Location}}" (click)="sortTherapeutsBy('Office1Location',false)"><i class="material-icons left">location_on</i>nach Ort</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor {{btSortByOffice1PostalCode}}" (click)="sortTherapeutsBy('Office1PostalCode',false)"><i class="material-icons left">local_post_office</i>nach PLZ</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor" (click)="openPDfGenerationPage()"><i class="material-icons left ">file_download</i>PDF donwload</a>
        </div>
    </div>
    <div class="row hide-on-large-only">
        <div class="col s12 m2 l3">
        </div>
        <div class="col s12 m10 l9">
            <h1>VAPP Therapeut*innen - sortiert {{currentSortMethodDisplay}}</h1>
            <a class="btn vappbgcolor {{btSortBySecondName}}" (click)="sortTherapeutsBy('secondName', false)">Nachnamen</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor {{btSortByOffice1Location}}" (click)="sortTherapeutsBy('Office1Location',false)">Ort</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor {{btSortByOffice1PostalCode}}" (click)="sortTherapeutsBy('Office1PostalCode',false)">PLZ</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor" (click)="openPDfGenerationPage()"><i class="material-icons left ">file_download</i>PDF</a>
        </div>
    </div>

    <div class="row" *ngFor="let therapeut of therapeuts">
        <div class="col s12 m2 l3 "></div>
        <div class="col s12 m10 l5 ">
            <div class="card horizontal">
                <div class="image-container valign-wrapper">
                    <!--"card-image" excluded-->
                    <div class="responsive-img centerimage">
                        <img src="{{picPathUrl}}{{therapeut.PicturePath}}">
                    </div>
                </div>
                <div class="card-stacked">
                    <div class="card-content">
                        <p><strong><a class="therapeutLink" routerLink="//therapeutdetails/{{therapeut.ID}}" routerLinkActive="active">{{therapeut.academicTitle}} {{therapeut.firstName}} {{therapeut.secondName}}</a></strong></p>
                        <p class="styledText"> {{therapeut.Office1PostalCode}} {{therapeut.Office1Location}}</p>
                    </div>
                    <div class="card-action right-align">
                        <a class="btn vappbgcolor" routerLink="//therapeutdetails/{{therapeut.ID}}" routerLinkActive="active"><i class="material-icons">more_horiz</i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- REPEAT BUTTONS AT LOWER END OF LIST -->
    <div class="row hide-on-med-and-down">
        <div class="col s12 m2 l3">
        </div>
        <div class="col s12 m10 l9">
            <a class="btn vappbgcolor {{btSortBySecondName}}" (click)="sortTherapeutsBy('secondName', false)"><i class="material-icons left">sort_by_alpha</i>nach Nachnamen</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor {{btSortByOffice1Location}}" (click)="sortTherapeutsBy('Office1Location',false)"><i class="material-icons left">location_on</i>nach Ort</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor {{btSortByOffice1PostalCode}}" (click)="sortTherapeutsBy('Office1PostalCode',false)"><i class="material-icons left">local_post_office</i>nach PLZ</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor" (click)="scrollToTop() "><i class="material-icons left ">arrow_upward</i></a>
        </div>
    </div>
    <div class="row hide-on-large-only">
        <div class="col s12 m2 l3">
        </div>
        <div class="col s12 m10 l9">
            <a class="btn vappbgcolor {{btSortBySecondName}}" (click)="sortTherapeutsBy('secondName', false)">Nachnamen</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor {{btSortByOffice1Location}}" (click)="sortTherapeutsBy('Office1Location',false)">Ort</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor {{btSortByOffice1PostalCode}}" (click)="sortTherapeutsBy('Office1PostalCode',false)">PLZ</a>&nbsp;&nbsp;
            <a class="btn vappbgcolor" (click)="openPDfGenerationPage()"><i class="material-icons left ">file_download</i>PDF</a>
            <a class="btn vappbgcolor" (click)="scrollToTop() "><i class="material-icons left ">arrow_upward</i></a>
        </div>
    </div>
</div>