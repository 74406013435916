import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieConsentService } from '../app-servicies/cookie-consent-service';
import { Therapeut } from '../classes/therapeut';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'therapeutdetails',
  templateUrl: './therapeut-details.component.html',
  styleUrls: ['./therapeut-details.component.css']
})
export class TherapeutDetailsComponent implements OnInit {

  picPathUrl: string = "https://www.vapp.at/therapeutpics/"; 
  baseUrl: string = "https://www.vapp.at/server_new/get_all_therapeuts.php";

  therapeutID = ""; 
  sub: any;
  therapeuts: Therapeut[] = null; 
  currentTherapeut: Therapeut; 
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;

  qrCodeInfo = "";
  showLandLineNumberQR = false; 
  showMobileNumberQR = false; 


  constructor(
    private _activatedRoute:ActivatedRoute,
    private _router:Router,
    public httpClient: HttpClient,
    public cookieConsentService: CookieConsentService ) { 
  }

  ngOnInit() {
    this.sub = this._activatedRoute.paramMap.subscribe(params => { 
      this.therapeutID = params.get('id');  
      this.httpClient.get(this.baseUrl).subscribe((res:any)=> {
       this.therapeuts = this.createTherapeuts(res);  

     })
   });
  }
  createTherapeuts(data: any) {
    let rValue: Therapeut[] = []; 
    for (let i: number = 0; i < data.length; i++)
    {
      var therapeut: Therapeut = new Therapeut(data[i]);  
      if (therapeut.ID == this.therapeutID)
      {
        this.currentTherapeut = therapeut; 
        if (this.currentTherapeut)
        {
          this.createQRCodeInfo(); 
        }
      }
      rValue.push(therapeut);
    }
    return rValue; 
  }
  openTherapeutWebSite(urlStr:string): void
  {
    window.open("//" + urlStr, '_blank');
  }
  openGoogleLink(mapStr:string): void
  {
    console.log("GOOGLE MAPS NOW: " + mapStr)
  }

  handleLandLineNumberQRCode() {
    this.showLandLineNumberQR = !this.showLandLineNumberQR; 
  }

  handleMobileQRCode() {
    this.showMobileNumberQR = !this.showMobileNumberQR;
  }

  createQRCodeInfo() {
    this.qrCodeInfo = ""; 
    if (this.currentTherapeut.academicTitle)
    {
      this.qrCodeInfo += this.currentTherapeut.academicTitle + " ";   
    }
    this.qrCodeInfo += this.currentTherapeut.firstName + " " + this.currentTherapeut.secondName;   
    if (this.currentTherapeut.MobileNumber)
    {
      this.qrCodeInfo += " Mobil: " + this.currentTherapeut.MobileNumber; 
    }
    if (this.currentTherapeut.LandLineNumber)
    {
      this.qrCodeInfo += " Festnetz: " + this.currentTherapeut.LandLineNumber; 
    }
    if (this.currentTherapeut.EMail)
    {
      this.qrCodeInfo += " Email: " + this.currentTherapeut.EMail; 
    }
    if (this.currentTherapeut.Website)
    {
      this.qrCodeInfo += " Webseite: " + this.currentTherapeut.Website; 
    }
  }
}
