import { CookieConsentSettings } from "./cookie-consent-settings";

export class CookieConsentService
{
    public cookieSettings: CookieConsentSettings; 
    cookieSettingsLabel = "vapp-cookies-settings";
    cookieConsentFirstStart = false; 
    
    constructor()
    {
        // CHECK FOR DEPRECIATED "googleRecaptchaEnabled"
        if (localStorage.getItem("vapp-cookies-settings")) {
            var cookiesSettings = JSON.parse(localStorage.getItem(this.cookieSettingsLabel));
            if (cookiesSettings.hasOwnProperty("googleRecaptchaEnabled")) {
                this.removeValue(this.cookieSettingsLabel);
            }
        }
        this.cookieSettings = JSON.parse(localStorage.getItem(this.cookieSettingsLabel)) as CookieConsentSettings;
        if (this.cookieSettings == null)
        {
            this.cookieConsentFirstStart = true;
            this.cookieSettings = new CookieConsentSettings();
        } 
    }
    loadSettings(): CookieConsentSettings
    {
        if (localStorage.getItem(this.cookieSettingsLabel) == "undefined")
        {
          this.cookieSettings = new CookieConsentSettings();
          return this.cookieSettings;
        }
        if (JSON.parse(localStorage.getItem(this.cookieSettingsLabel)) != null) {
          var tempS: CookieConsentSettings = JSON.parse(localStorage.getItem(this.cookieSettingsLabel)) as CookieConsentSettings;
          return JSON.parse(localStorage.getItem(this.cookieSettingsLabel)) as CookieConsentSettings;
        } else
        {
          this.cookieSettings = new CookieConsentSettings();
          return this.cookieSettings;
        }
    }
    setCookieConsentVisisble(): void
    {
        if (this.cookieSettings == null) { this.cookieSettings = new CookieConsentSettings();}
        this.cookieSettings.cookieConsentIsVisible = !this.cookieSettings.cookieConsentIsVisible;
        this.saveSettings(); 
        this.loadSettings();
    }
    setGoogleMaps(): void
    {
        if (this.cookieSettings == null) { this.cookieSettings = new CookieConsentSettings();}
        this.cookieSettings.googleMapsEnabled = !this.cookieSettings.googleMapsEnabled;
        this.saveSettings(); 
        this.loadSettings();
    }
    saveSettings()  { localStorage.setItem(this.cookieSettingsLabel, JSON.stringify(this.cookieSettings)); }
    removeValue(idStr: string) { localStorage.removeItem(idStr); }
}