<body>
    <header id="mainHeader" class="mainHeader valign-wrapper">
        <nav class="nav-wrapper vappbgcolor nav-center">
            <div class="row ">
                <div class="col s12 m2 l2 horizontalcenterstyle">
                    <div class="brand-logo">
                        <a routerLink='app-home' routerLinkActive="active">
                            <img class="responsive-img" src="./assets/contents/images/vapp_logo_2021.png" alt="VAPP Logo">
                        </a>
                    </div>
                </div>
                <a href class="sidenav-trigger" data-target="mobile-links"> <i class="material-icons">menu</i></a>
                <div class="col s12 m10 l10">


                    <ul class="left hide-on-med-and-down">
                        <li><a class="headerNavStyledText" routerLink='app-home' routerLinkActive="active">Home</a></li>
                        <li><a class="headerNavStyledText" routerLink='offer' routerLinkActive="active">Was bietet VAPP?</a></li>
                        <li><a class="headerNavStyledText" routerLink='steps' routerLinkActive="active">Welche Schritte sind zu tun?</a></li>
                        <li><a class="headerNavStyledText" routerLink='service' routerLinkActive="active">VAPP Servicestelle</a></li>
                        <li><a class="headerNavStyledText" routerLink="therapeuts" routerLinkActive="active">VAPP Therapeut*innen</a></li>
                    </ul>

                </div>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-links">
            <li><a class="sidenav-close headerNavStyledText sidenavtext" routerLink="/app-home">Home</a></li>
            <li><a class="sidenav-close headerNavStyledText sidenavtext" routerLink="/offer">Was bietet VAPP?</a></li>
            <li><a class="sidenav-close headerNavStyledText sidenavtext" routerLink="/steps">Welche Schritte sind zu tun?</a></li>
            <li><a class="sidenav-close headerNavStyledText sidenavtext" routerLink="/service">VAPP Servicestelle</a></li>
            <li><a class="sidenav-close headerNavStyledText sidenavtext" routerLink="/therapeuts">VAPP Therapeut*innen</a></li>
            <li><a class="sidenav-close headerNavStyledText sidenavtext" routerLink="/service">Kontakt</a></li>
            <li><a class="sidenav-close headerNavStyledText sidenavtext" routerLink="/impressum">Impressum</a></li>
            <li><a class="sidenav-close headerNavStyledText sidenavtext" routerLink="/dataprotection">Datenschutz</a></li>
        </ul>
    </header>

    <main>
        <router-outlet></router-outlet>
    </main>

    <footer class="hide-on-med-and-down vappbgcolor footer-outer">
        <div class="row valign-wrapper footer-inner">
            <div class="col s12 m12 l12 center-align">
                <a routerLink="/impressum" routerLinkActive="active">Impressum</a> /
                <a routerLink="/dataprotection" routerLinkActive="active">Datenschutzerklärung</a> /
                <a routerLink="/service" routerLinkActive="active">Kontakt</a>
            </div>
        </div>
    </footer>
</body>