import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { OfferComponent } from './offer/offer.component';
import { StepsComponent } from './steps/steps.component';
import { ServiceComponent } from './service/service.component';
import { TherapeutsComponent } from './therapeuts/therapeuts.component';
import { TherapeutDetailsComponent } from './therapeut-details/therapeut-details.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DataprotectionComponent } from './dataprotection/dataprotection.component';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    
  ]
})
export class AppRoutingModule { }
