<div class="row styledText">
    <div class="col s12 m2 l2"></div>
    <div class="col s12 m10 l6">
        <div>
            <h1>Was ist VAPP?</h1>
            <div><strong>V</strong>erein für <strong>A</strong>mbulante <strong>P</strong>sychologische <strong>P</strong>sychotherapie</div>
            <div>
                Im Jahr 2007 wurden Leistungsvereinbarungen für Psychotherapie in der Steiermark zwischen der Steiermärkischen Gebietskrankenkasse - Abteilung Geschäftsausschuss – Vertragspartner (aktuell: Österreichische Gesundheitskasse - Steiermark), und dem „VAPP“
                abgeschlossen.
            </div>
            <br>
            <div><span>Der Verein <strong>VAPP</strong> stellt in der Steiermark...</span>
                <ul>
                    <li>... ein Kontingent für Psychotherapie auf Krankenschein</li>
                    <li>... ein Kontingent an VAPP-Vertragspartner*innen in den Regionen</li>
                    <li>... eine Servicestelle zur Klärung von Patient*innenanfragen</li>
                    <li>... eine Servicestelle zur Verrechnung mit den Krankenversicherungsträgern</li>
                </ul>
            </div>
            <div>VAPP-Vertragspartner*innen sind freiberuflich tätige Psychotherapeut*innen, die zugleich auch klinische Psycholog*innen sind. Patient*innen haben somit Zugang zu höchstqualifizierter psychotherapeutischer Versorgung im gesamten steirischen
                Landesgebiet.
            </div>
        </div>
        <div class="narrow-lines">
            <h2>Weitere Links</h2>
            <ul>
                <li><a class="styledTextBlue" href="https://gesundheitsfonds-steiermark.at/plattform-psyche/psychotherapie/" target="_blank">Steiermark - Psychotherapie</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- cookie consent -->
<div class="row styledText" *ngIf="cookieConsentService.cookieConsentFirstStart && cookieConsentService.cookieSettings.cookieConsentIsVisible">
    <div class="col s12 m2 l2"></div>
    <div class="col s12 m10 l6">
        <div class="vappbgcolorlight black-text">
            <div class="right-align"><a class="btn vappbgcolor right-align" (click)="setCookieConsentVisisble()"><i class="material-icons">close</i></a></div>
            <div class="center-align styledTextBlue">Cookie Zustimmung</div>
            <br>
            <div class="center-align">
                Diese Internetseite verwendet Cookies durch Drittanbieter.
                <br>Durch die weitere Nutzung der Website stimmen Sie der Verwendung dieser Cookies zu.<br>Weitere Informationen hierzu finden Sie in unserer<br>
                <a class="styledTextBlue" routerLink="/dataprotection" routerLinkActive="active">Datenschutzerklärung.</a><br>Wir empfehlen unsere Cookies zu zulassen.
            </div>
            <hr>
            <div class="switch center-align">
                Google Maps: Um notwendige Geo-Location Links auf Google Maps zu präsentieren<br>
                <label class="styledTextBlue">
                Google Maps Off
                <input type="checkbox" (click)="mapsClicked()" [(checked)]="cookieConsentService.cookieSettings.googleMapsEnabled">
                <span class="lever"></span>
                Google Maps On
            </label>
            </div>
            <hr>
        </div>
    </div>
</div>