import { getLocalePluralCase, ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Therapeut } from '../classes/therapeut';
import { tap } from 'rxjs/operators';
import { Observable, pipe } from 'rxjs';
import M from 'materialize-css/dist/js/materialize';

@Component({
  selector: 'therapeuts',
  templateUrl: './therapeuts.component.html',
  styleUrls: ['./therapeuts.component.css']
})


export class TherapeutsComponent implements OnInit,AfterViewInit {

  therapeuts: Therapeut[] = []; 
  therapeutData: any; 
  data: any; 
  baseUrl: string = "https://www.vapp.at/server_new/get_all_therapeuts.php"; 
  picPathUrl: string = "https://www.vapp.at/therapeutpics/"; 
  path2PDFCreationUrl: string = "https://www.vapp.at/server_new/pdf2021.php"; 
  btSortBySecondName = "disabled";
  btSortByOffice1Location = "enabled";
  btSortByOffice1PostalCode = "enabled";
  currentSortMethod = "secondName";
  currentSortMethodDisplay = "nach Nachnamen";
  serverResponse: any; 
  postData = {};
  error = false; 

  constructor( 
    public httpClient: HttpClient,
    private scroll: ViewportScroller) 
  {}
  
  ngOnInit(): void {  
    this.httpClient.post(this.baseUrl, 
      this.postData
      ).subscribe(responseData => {
          this.therapeutData = responseData; 
        },
        err => {
          this.handleError(err);
        },
        () => {
          this.therapeuts = this.therapeutData as Therapeut[];
        }
      );
  }

  ngAfterViewInit(): void {}

  // ERROR CATCHING
  private handleError(error: any): void { 
    this.error = true; 
  }

  sortTherapeutsBy(str:string, moveToTop: boolean)
  {
    this.currentSortMethod = str;
    // HANLDE SORT BUTTONS
    this.btSortBySecondName = "enabled";
    this.btSortByOffice1Location = "enabled";
    this.btSortByOffice1PostalCode = "enabled";
    switch (this.currentSortMethod)
    {
      case "secondName":
        this.btSortBySecondName = "disabled";
        this.therapeuts.sort((a,b) => a.secondName.localeCompare(b.secondName));
        this.currentSortMethodDisplay = "nach Nachnamen"
        break;
      case "Office1Location":
        this.btSortByOffice1Location = "disabled";
        this.therapeuts.sort((a,b) => a.Office1Location.localeCompare(b.Office1Location));
        this.currentSortMethodDisplay = "nach Ort"
        break; 
      case "Office1PostalCode":
          this.btSortByOffice1PostalCode = "disabled";
          this.therapeuts.sort((a,b) => a.Office1PostalCode.localeCompare(b.Office1PostalCode));
          this.currentSortMethodDisplay = "nach Postleitzahl"
          break; 
    }
    if (moveToTop)
    {
      this.scrollToTop(); 
    }
  }

  openPDfGenerationPage(): void
  {
    let getParam = "?flag=";
    let url = this.path2PDFCreationUrl + getParam;
    switch (this.currentSortMethod) {
      case "secondName":  url += "1"; break; 
      case "Office1Location":  url += "2"; break; 
      case "Office1PostalCode":  url += "5"; break; 
    }
    window.open(url, '_blank'); 
  }

  scrollToTop() {
    this.scroll.scrollToAnchor("mainHeader");
  }

  openTherapeutWebSite(urlStr:string): void
  {
    console.log("OPEN NOW: " + urlStr)
  }

  openGoogleLink(mapStr:string): void
  {
    console.log("GOOGLE MAPS NOW: " + mapStr)
  }

  switchToDetails(currentTherapeut: Therapeut): void
  {
    console.log("SWITCH TO:" + currentTherapeut.ID);
  }

  addFilter(): void
  {
    console.log("ADD FILTER:");
  }

}
