import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxCaptchaModule, ReCaptcha2Component } from 'ngx-captcha';
import { ContactService } from '../app-servicies/contact.service';
import { CookieConsentService } from '../app-servicies/cookie-consent-service';
import { MessageResponseObj } from '../classes/message-response-obj';


@Component({
  selector: 'service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css'],
  providers: [ContactService]
})

export class ServiceComponent implements OnInit, AfterViewInit {
  
  messageObj: any; 

  str_send_success = "Die Nachricht wurde uns zugestellt. Vielen Dank."
  str_send_error = "Ihre Nachricht konnte nicht übermittelt werden. Bitte versuchen Sie es später noch einmal, oder kontaktieren Sie uns über unsere anderen Kanäle.";
  str_sentMessage = "Ihre Nachricht wurde abgeschickt.";
  server_fb_message = ""; 
  str_possible_spam = "Die Spam-Erkennung hat leider angeschlagen. Bitte versuchen Sie das Formular langsamer auszufüllen, oder kontaktieren Sie uns über unsere anderen Kanäle."
  str_input_default_text = "Bitte füllen Sie das Formular vollständig aus."
  userMessage = "";

  serverURL = "https://www.vapp.at/server_new/formsend2.php";

  serverResponse: MessageResponseObj = null;  
  formularDisabled = true;

  maxTimeDifference = 8000; // 10 seconds threshold
  startingTimeStamp = "";
  isFormFilled: boolean = false;
  isFormValid: boolean = false;
  userFBMessages = new Array(); 
  btnSubmitClassName="disabled";
  
  formControlButtonsAreVisible = true;
  appFeedbackAvailable = false;

  constructor(private router: Router,private http: HttpClient, public cookieConsentService: CookieConsentService)  { }

  ngOnInit() {
    this.userFBMessages = [];
    this.userFBMessages[0] = this.str_input_default_text;
  }

  ngAfterViewInit() {
    this.startingTimeStamp = this.getCurrentTimestamp(); 
  }
  
  getCurrentTimestamp(): string { return new Date().toISOString(); }

  checkFormValidity(form: NgForm) {

    this.isFormValid = true; 
    this.isFormFilled = false; 
    this.userFBMessages = [];
    const fullname = form.value?.fullname || "";
    const topic = form.value?.topic || "";
    const message = form.value?.message || "";
    const email = form.value?.email || "";
    const honeypot = form.value?.honeypot || "";
    let formIsEmpty = fullname === "" && 
                      topic === "" && 
                      message === "" && 
                      email === "";
    if (formIsEmpty) {
      this.startingTimeStamp = this.getCurrentTimestamp(); 
    }
    this.isFormFilled =   fullname !== "" && 
                          topic !== "" && 
                          message !== ""  && 
                          email !== "";


    this.isFormValid =  fullname.length >= 3 && 
                        topic.length >= 3 && 
                        message.length >= 3 && 
                        this.validateEmail(email) &&
                        honeypot == ""; 
    if (!this.isFormValid)
    {
      if (fullname.length < 3)
      {
          this.userFBMessages.push("Geben Sie Ihren vollen Namen an.");
      }
      if (topic.length < 3) {
          this.userFBMessages.push("Geben Sie den Titel ihrer Nachricht an.");
      }
      if (message.length < 3) {
          this.userFBMessages.push("Schreiben Sie bitte ihre Nachricht.");
      }
      if (! this.validateEmail(email))
      {
          this.userFBMessages.push("Die E-Mail-Adresse scheint nicht zu stimmen.");
      }
      if (honeypot != "") {
          this.userFBMessages.push("Spam detected!'");
      }
    }
    if (!this.isFormFilled) {
      this.userFBMessages = [];  
      this.btnSubmitClassName = "disabled";
    }
    if (this.isFormFilled) {
      if (!this.isFormValid) {
        this.btnSubmitClassName = "disabled";
      } else {
        this.btnSubmitClassName = "enabled"
      }
    }
  }

  validateEmail(email: string): boolean {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  submitForm(form: NgForm) {

    this.checkFormValidity(form); 
    if (!this.isFormValid) {
      // Display error messages based on form validation
      // this.userFBMessages[0] = "Da ist ein Fehler passiert. Verwenden Sie bitte die Email Adresse oder versuchen Sie es zu einem späteren Zeitpunkt nocheinmal."
      return; 
    } else {


      // Check timestamp for spam prevention
      const submissionTime = new Date(this.startingTimeStamp);
      const currentTime = new Date();
      const timeDifference = currentTime.getTime() - submissionTime.getTime();
      if (timeDifference < this.maxTimeDifference) {
          // Form submitted before threshold time, consider it as spam
          this.userFBMessages[0] = "";
          this.server_fb_message = this.str_possible_spam;
          this.appFeedbackAvailable = true; 
          this.formControlButtonsAreVisible = false;
          return;
      } else 
      {
        console.log("fullname:" + form.value['fullname'].trim());
        console.log("email:" + form.value['email'].trim());
        console.log("topic:" + form.value['topic'].trim());
        console.log("message: " + form.value['message'].trim());
        console.log("honeypot: " + form.value['honeypot']);
        this.userFBMessages[0] = "";
        this.formControlButtonsAreVisible = false;
        this.submitFormNow(form); 
      }
    }
    
  }

  resetForm(form: NgForm) {
    this.isFormFilled = false; 
    this.isFormValid = false; 
    this.userFBMessages = [];
    this.userFBMessages[0] = this.str_input_default_text;
    this.btnSubmitClassName = "disabled";
    this.messageObj = null; 
    this.server_fb_message = ""; 
    this.userMessage = ""; 
    this.serverResponse = null; 
    this.formControlButtonsAreVisible = true;
    this.appFeedbackAvailable = false;
    this.startingTimeStamp = this.getCurrentTimestamp(); 
    form.resetForm();
   //  this.router.navigate(["service"]);
  }

  submitFormNow(form: NgForm) {
    console.log("SUBMIT NOW!");

    this.messageObj = {
      fullname:"",
      email:"",
      topic:"",
      message:""
    }
    this.messageObj.fullname = form.value['fullname'].trim();
    this.messageObj.email = form.value['email'].trim();
    this.messageObj.topic = form.value['topic'].trim();
    this.messageObj.message = form.value['message'].trim();

    let query = JSON.stringify(this.messageObj);
    let httpOptions = {
      headers: new HttpHeaders({
          'Content-Type':  'application/json'
      })
    };

    return this.http.post(this.serverURL, JSON.stringify(query), httpOptions)
    .subscribe(
        (data: MessageResponseObj) => {
            data.returnData = JSON.parse(data.returnData);
            this.serverResponse = data; 
            if (this.serverResponse.status == "OK")
            {
              this.server_fb_message = this.str_send_success;
              this.appFeedbackAvailable = true; 
            }
            if (this.serverResponse.status == "ERROR")
              {
                this.server_fb_message = this.str_send_error;
                this.appFeedbackAvailable = true; 
                this.serverResponse = null;  
              }
        },
        err => {
          this.server_fb_message = this.str_send_error;
          this.appFeedbackAvailable = true; 
          this.serverResponse = null;  
        },
        () => {
          console.log('HTTP request completed.');
          console.log('status: ' + this.serverResponse.status);
          console.log('message: ' + this.serverResponse.message);
        }
      );   
    }
  }