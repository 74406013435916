<div class="row styledText">
    <div class="col s12 m2 l2"></div>
    <div class="col s12 m10 l10">
        <div>
            <h1>Welche Schritte sind zu tun?</h1>
            <div>Wenn Sie in der Steiermark einen Therapieplatz suchen, können Sie ...
                <ol>
                    <li><a class="btn vappbgcolor" routerLink="//therapeuts/" routerLinkActive="active"><i class="material-icons">arrow_forward</i></a>&nbsp;&nbsp;selbstständig Kontakt zu den VAPP-Psychotherapeut*innen aufnehmen</li>
                    <li><a class="btn vappbgcolor" routerLink="//service/" routerLinkActive="active"><i class="material-icons">arrow_forward</i></a>&nbsp;&nbsp;das Kontaktformular nutzen</li>
                    <li><a class="btn vappbgcolor" routerLink="//service" routerLinkActive="active"><i class="material-icons">arrow_forward</i></a>&nbsp;&nbsp;bei unserer Servicestelle nachfragen </li>
                </ol>
            </div>
        </div>
    </div>
</div>