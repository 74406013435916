<div class="row">
    <div class="col col s12 m10 l8 offset-s0 offset-m1 offset-l2">
        <div class="therapeut-details">
            <div class="therapeut-details-upper-container">
                <div class="image-container valign-wrapper">
                    <div class="responsive-img centerimage">
                        <img src="{{picPathUrl}}{{currentTherapeut.PicturePath}}">
                    </div>
                </div>
                <div class="therapeut-name-container">
                    <div>
                        <strong>{{currentTherapeut.academicTitle}} {{currentTherapeut.firstName}} {{currentTherapeut.secondName}}</strong>
                    </div>
                    <div class="styledText">
                        {{currentTherapeut.Office1PostalCode}}, {{currentTherapeut.Office1Location}}
                    </div>
                    <div *ngIf="currentTherapeut.EMail" style="margin-bottom: 1rem;">
                        <a  class="therapeuttext valign-wrapper" href="mailto:{{currentTherapeut.EMail}}">
                            <i class="material-icons left">email</i> 
                            {{currentTherapeut.EMail}}
                        </a>
                    </div>
                    <div *ngIf="currentTherapeut.Website">
                        <a  (click)="openTherapeutWebSite(currentTherapeut.Website)" target="_blank" class="btn vappbgcolor valign-wrapper">
                            <i class="material-icons left">web</i>
                            {{currentTherapeut.Website}}
                        </a>
                    </div>
                </div>
            </div>   
            <div class="therapeut-details-middle-container">
                <div class="therapeut-details-phone-info" *ngIf="currentTherapeut.MobileNumber">
                    <div class="w-50 bordered-box">
                       <div class="row-container">
                           <a class="therapeuttext valign-wrapper" href="tel:{{currentTherapeut.MobileNumber}}">
                               <i class="material-icons left">settings_cell</i>
                               {{currentTherapeut.MobileNumber}}
                           </a>
                           <button class="btn vappbgcolor" style="margin-left:1rem;" (click)="handleMobileQRCode()" >QR Code</button>
                       </div>
                       <ngx-qrcode
                           *ngIf="showMobileNumberQR"
                           [elementType]="elementType"
                           [errorCorrectionLevel]="correctionLevel"
                           [value]="currentTherapeut.qrCodeMobileNumber"
                           alt="Mobile Number"
                           cssClass="bshadow">
                       </ngx-qrcode>
                   </div>
               </div>
               <div class="therapeut-details-phone-info" *ngIf="currentTherapeut.LandLineNumber">
                    <div class="w-50 bordered-box">
                        <div class="row-container">
                            <a class="therapeuttext valign-wrapper" href="tel:{{currentTherapeut.LandLineNumber}}">
                                <i class="material-icons left">settings_phone</i>
                                {{currentTherapeut.LandLineNumber}}
                            </a>
                            <button class="btn vappbgcolor" style="margin-left:1rem;"  (click)="handleLandLineNumberQRCode()">QR Code</button>
                        </div>
                        <ngx-qrcode *ngIf="showLandLineNumberQR"
                            [elementType]="elementType"
                            [errorCorrectionLevel]="correctionLevel"
                            [value]="currentTherapeut.qrCodeLandlineNumber"
                            alt="Festnetz Nummber"
                            cssClass="bshadow">
                        </ngx-qrcode>
                    </div>
                </div>
            </div>
            <div class="therapeut-details-middle-container" *ngIf="currentTherapeut.AdditionalInfo" style="padding-top: 0rem;">
                <span class="therapeuttext valign-wrapper">{{currentTherapeut.AdditionalInfo}}</span>
            </div>
            <div class="therapeut-details-middle-container" *ngIf="currentTherapeut.locations">
                <div class="therapeut-details-middle-container-praxis" 
                    *ngFor="let location of currentTherapeut.locations; let last = last"
                    [ngClass]="{'therapeut-details-middle-container-praxis-last': last}"
                    >
                    <span><strong>{{location.headline}}</strong></span><br>
                    <span>{{location.address}}, {{location.postalCode}}, {{location.location}}</span> <br>
                    <a class="btn vappbgcolor" *ngIf="cookieConsentService.cookieSettings.googleMapsEnabled" href="{{location.googleMapLink}}" target="_blank"><i class="material-icons">location_searching</i>&nbsp;Google maps</a>
                </div>
            </div>
            <div class="therapeut-details-bottom-container">
                <a class="btn vappbgcolor therapeut-details-back-btn" routerLink="//therapeuts/" routerLinkActive="active"><i class="material-icons">arrow_back</i>&nbsp;zurück</a>
            </div>
        </div>
    </div>
</div>