import { Component, OnInit } from '@angular/core';
import { CookieConsentService } from '../app-servicies/cookie-consent-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public cookieConsentService: CookieConsentService) { }

  ngOnInit(): void {}

  setCookieConsentVisisble(): void
  {
    this.cookieConsentService.setCookieConsentVisisble(); 
  }
  mapsClicked(): void
  {
    this.cookieConsentService.setGoogleMaps(); 
  }
}
