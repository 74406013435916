<div class="row styledText">
    <div class="col s12 m2 l2"></div>
    <div class="col s12 m10 l10">
        <div>
            <h1>Was bietet VAPP?</h1>
            <div><strong>Psychotherapie auf Krankenschein</strong></div>
            <div>Wie kommen Sie zu einer Psychotherapie auf Krankenschein?</div>
            <br>
            <div><strong>Für eine kostenfreie Psychotherapie ist eine Versicherung bei einer der folgenden Krankenkassen erforderlich:</strong></div>
            <div>
                <ul>
                    <li>
                        <a class="btn vappbgcolor" href="https://www.gesundheitskasse.at/cdscontent/?contentid=10007.831362&portal=oegkstportal&viewmode=content" target="_blank"><i class="material-icons">arrow_forward</i></a> &nbsp;
                        <strong><a class = "styledTextBlue" href="https://www.gesundheitskasse.at/cdscontent/?contentid=10007.831362&portal=oegkstportal&viewmode=content" target="_blank">Österreichische Gesundheitskasse (ÖGK-ST)</a></strong>
                    </li>

                    <li>
                        <a class="btn vappbgcolor" href="https://www.bvaeb.at/cdscontent/?contentid=10007.842441&viewmode=content" target="_blank"><i class="material-icons">arrow_forward</i></a> &nbsp;
                        <strong><a class = "styledTextBlue" href="https://www.bvaeb.at/cdscontent/?contentid=10007.842441&viewmode=content" target="_blank">Versicherungsanstalt öffentlich Bediensteter, Eisenbahnen und Bergbau (bvaeb)</a></strong>
                    </li>

                    <li>
                        <a class="btn vappbgcolor" href="https://www.graz.at/cms/beitrag/10014888/7751070/KFA_Krankenfuersorgeanstalt.html" target="_blank"><i class="material-icons">arrow_forward</i></a> &nbsp;
                        <strong><a class = "styledTextBlue" href="https://www.graz.at/cms/beitrag/10014888/7751070/KFA_Krankenfuersorgeanstalt.html" target="_blank">Krankenfürsorgeanstalt für Beamte der Landeshauptstadt Graz (KFA)</a></strong>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>